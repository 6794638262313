import "./utils/table.js";
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import vue3GoogleLogin from "vue3-google-login";
import Select2 from 'vue3-select2-component';

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);

appInstance.use(vue3GoogleLogin, {clientId:"476190431556-muqp12amefr0pk9qn2vmvrnoqej36u1i.apps.googleusercontent.com",});

appInstance.component('Select2', Select2)

appInstance.mount("#app");
