<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header bg-gray-200 text-start">
                  <h4 class="font-weight-bolder">Bienvenido</h4>
                  <p class="mb-0">
                    Sistema de gestión de pagos
                    <b class="text-info text-gradient">POSGRADO</b>
                  </p>
                </div>
                <div class="card-body bg-gray-200 rounded-bottom-4">
                  <GoogleLogin :callback="callback" prompt :error="error" />
                </div>
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <a href="javascript:;" class="text-info text-gradient font-weight-bold">Sign up</a>
                  </p>
                </div> -->
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(./101.jpg);
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                "
              >
                <span class=""></span>
                <h4
                  class="mt-5 mb-0 font-weight-bolder text-dark position-relative"
                >
                  Escuela de POSGRADO
                </h4>
                <small class="mb-2 text-info text-gradient"
                  >Universidad Nacional de Piura</small
                >
                <!-- <p class="text-dark position-relative">
                  "Nuestra mayor debilidad reside en rendirnos. La forma más
                  segura de tener éxito es intentarlo una vez más". <br /><b
                    >Thomas A. Edison</b
                  >.
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import _Services from "../../services/AuthService.js";
import Swal from "@/plugins/sweetalert2.js";

export default {};
</script>

<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { decodeCredential } from "vue3-google-login";
//import _Services from "../../services/AuthService.js";
//import { googleLogout } from "vue3-google-login"

const body = document.getElementsByTagName("body")[0];
//let disabled = true;

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});

onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

const callback = async (response) => {
  const userData = decodeCredential(response.credential);
  console.log("Handle the userData", userData);
  //if (userData.email && userData.email.includes("gmail.com")) {
    const params = {
      email: userData.email,
      name: userData.name,      
      clientId: userData.sub,
      urlPhoto: userData.picture,
    };

    try {
      const request = await _Services.authStudent(params);
      const response = request.data;
      console.log(response.status);
      if( response.status == 'error_access') {
        Swal.error(response.message);
      }

      if (response.status == 'success') {
        const responseAuth = await _Services.authLogin(userData.email, userData.name);
        console.log('success',responseAuth);
        //window.location.reload();
        window.location.replace('https://mispagos.epgunp.com/');
        //window.location.replace('http://localhost:8080/');
      }
      if (response.status == 'success_exists') {
        const responseAuth = await _Services.authLogin(userData.email, userData.name);
        console.log('success_exists',responseAuth);
        //window.location.reload();
        window.location.replace('https://mispagos.epgunp.com/');
        //window.location.replace('http://localhost:8080/');
      }
      if (response.status == 'error') {
        console.log(response.error);        
      }
    }catch(err) {
      console.log("error_login: " + err.message);
    }    
  /* } else {
    console.log("El correo electrónico no es de la UNP");
  } */

};
const error = () => {
  console.log("Handle the error");
};
/* const logout = () => {
  // your logout logics
  googleLogout();
} */
</script>
