export const setUsers = ( state, users ) => {
    state.users = [ ...state.users, ...users]
    state.isLoading = false
}

export const updatetUsers = (/* state */) => {

}

export const createUsers = (/* state */) => {

}