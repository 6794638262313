import axios from 'axios';

const instance = axios.create({
    //baseURL: 'http://127.0.0.1:8000/api'
    baseURL: 'https://apipos.epgunp.com/api'
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Obtén el token desde el almacenamiento local o de donde lo estés guardando
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
);

export default instance;