const checkAuthentication = async () => {
    const token = localStorage.getItem('token');
    return !!token; // Devuelve true si hay un token, false si no lo hay
}

const isAuthenticatedGuard = async (to, from, next) => {
    try {
        const isAuthenticated = await checkAuthentication();
        if (isAuthenticated) {
            console.log("Estas autenticado");
            next();
        } else {
            console.log("Bloqueado por el Guard")            
            next({ name: '/' });
        }
    } catch (error) {
        console.error("Error en el guard:", error);
        next({ name: '/' }); // Redirigir en caso de error
    }
}

export default isAuthenticatedGuard;
