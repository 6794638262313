export default {
    name: 'Codigo Pago',
    component: () => import('@/modules/payment-code/layouts/PaymentLayout.vue'),
    children: [
        {
            path: 'codigo-pago',
            name: 'Codigo Pago',
            component: () => import('@/modules/payment-code/views/PaymentCode.vue'),
        }
    ]
}