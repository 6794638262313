import ServiceAPI from '../utils/axios.js';

const url = '/auth';

export default {
    auth(params) {
        return ServiceAPI.post(`${url}/login`, params)
            .then((response) => {
                const { token } = response.data;
                localStorage.setItem('token', token); // Guarda el token en el almacenamiento local
                return response;
            });
    },

    authLogin(email, name) {
        localStorage.setItem('authentication', JSON.stringify({email: email, name: name}));

        return {email: email};
    },

    logout() {
        const url = '/auth/logout';
        return ServiceAPI.post(url, {})
            .then(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('authentication');
                return Promise.resolve();
            })
            .catch(error => {
                console.error('Error al cerrar sesión en el backend:', error);
                return Promise.reject(error);
            });
    },
    
    authStudent(params) {
        return ServiceAPI.post(`${url}/student`, params)
            .then((response) => {
                const { student, status } = response.data;                

                if( status == 'error_access' ) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('authentication');
                } else {
                    localStorage.setItem('token', student.token); // Guarda el token en el almacenamiento local
                }                
                return response;
            });
    },
}

