<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";
//import SidenavCard from "./SidenavCard.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const isSettingsDropdownOpen = ref(false);

const toggleSettingsDropdown = () => {
  isSettingsDropdownOpen.value = !isSettingsDropdownOpen.value;
  console.log(isSettingsDropdownOpen.value)
};

const getRoute = () => {
    const route = useRoute();
    const routeArr = route.path.split("/");
    return routeArr[1];
};
</script>
<script>
export default {
    data() {
        return {
            isGoogle: true
        }
    }
}
</script>
<template>
    <div v-if="!isGoogle" class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
        <ul class="navbar-nav">
            <li class="nav-item">
                <sidenav-item to="/dashboard" :class="getRoute() === 'dashboard' ? 'active' : ''"
                    :navText="isRTL ? 'لوحة القيادة' : 'Dashboard'">
                    <template v-slot:icon>
                        <i class="ni ni-tv-2 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="mt-3 nav-item">
                <h6 v-if="isRTL" class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    صفحات المرافق
                </h6>
                <h6 v-else class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    DEPÓSITOS
                </h6>
            </li>
            <li class="nav-item">
                <sidenav-item to="/depositos-sync" :class="getRoute() === 'depositos-sync' ? 'active' : ''"
                    :navText="isRTL ? 'الجداول' : 'Sincronizar'">
                    <template v-slot:icon>
                        <i class="ni ni-cloud-download-95 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/depositos-gestionar" :class="getRoute() === 'depositos-gestionar' ? 'active' : ''"
                    :navText="isRTL ? 'الفواتیر' : 'Gestionar'">
                    <template v-slot:icon>
                        <i class="ni ni-credit-card text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="mt-3 nav-item">
                <h6 v-if="isRTL" class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    صفحات المرافق
                </h6>
                <h6 v-else class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    MAESTROS
                </h6>
            </li>
            <li class="nav-item">
                <sidenav-item to="/maestro-cursos" :class="getRoute() === 'maestro-cursos' ? 'active' : ''"
                    :navText="isRTL ? 'الواقع الافتراضي' : 'Cursos'">
                    <template v-slot:icon>
                        <i class="ni ni-books text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/maestro/codigo-pago" :class="getRoute() === 'maestro/codigo-pago' ? 'active' : ''"
                    :navText="isRTL ? 'الواقع الافتراضي' : 'Codigo Pagos'">
                    <template v-slot:icon>
                        <i class="ni ni-archive-2 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/maestro-alumnos" :class="getRoute() === 'maestro-alumnos' ? 'active' : ''"
                    :navText="isRTL ? 'الواقع الافتراضي' : 'Alumnos'">
                    <template v-slot:icon>
                        <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="mt-3 nav-item">
                <h6 v-if="isRTL" class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    صفحات المرافق
                </h6>
                <h6 v-else class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    REPORTES
                </h6>
            </li>
            <li class="nav-item">
                <sidenav-item to="/reporte-sistemaingresos"
                    :class="getRoute() === 'reporte-sistemaingresos' ? 'active' : ''"
                    :navText="isRTL ? 'حساب تعريفي' : 'Sistema Ingresos UNP'">
                    <template v-slot:icon>
                        <i class="ni ni-folder-17 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/reporte-deudas" :class="getRoute() === 'reporte-deudas' ? 'active' : ''"
                    :navText="isRTL ? 'حساب تعريفي' : 'Deudas'">
                    <template v-slot:icon>
                        <i class="ni ni-chart-bar-32 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="nav-item">
                <sidenav-item to="/reporte-alumnos" :class="getRoute() === 'reporte-alumnos' ? 'active' : ''"
                    :navText="isRTL ? 'الواقع الافتراضي' : 'Alumnos'">
                    <template v-slot:icon>
                        <i class="ni ni-bullet-list-67 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="mt-3 nav-item sidebar-item">
                <a @click="toggleSettingsDropdown"  style="cursor: pointer;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                    aria-controls="collapseOne"
                    class="text-xs mb-2 ps-4 text-uppercase font-weight-bolder opacity-6 sidebar-link collapsed has-dropdown d-flex align-items-center justify-content-between"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    <span>AJUSTES</span>
                    <i :class="isSettingsDropdownOpen ? 'fa fa-chevron-up ms-2 me-4' : 'fa fa-chevron-down ms-2 me-4'"></i>
                </a>
                <ul id="collapseOne" class="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar" :class="{ 'show': isSettingsDropdownOpen.value }">
                    <li>
                        <sidenav-item to="/settings/users" class="sidebar-item"
                            :class="getRoute() === 'users' ? 'active' : ''"
                            :navText="isRTL ? 'تسجيل الدخول' : 'Usuarios'">
                            <template v-slot:icon>
                                <i class="ni ni-circle-08 text-dark text-sm opacity-10"></i>
                            </template>
                        </sidenav-item>
                    </li>
                    <li>
                        <sidenav-item to="/settings/roles" class="sidebar-item" :class="getRoute() === 'roles' ? 'active' : ''"
                            :navText="isRTL ? 'اشتراك' : 'Rol'">
                            <template v-slot:icon>
                                <i class="ni ni-ungroup text-dark text-sm opacity-10"></i>
                            </template>
                        </sidenav-item>
                    </li>
                </ul>
            </li>
            
            <!-- <li class="nav-item">
        <sidenav-item
          to="/usuarios"
          :class="getRoute() === 'usuarios' ? 'active' : ''"
          :navText="isRTL ? 'تسجيل الدخول' : 'Usuarios'"
        >
          <template v-slot:icon>
            <i class="ni ni-circle-08 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/roles"
          :class="getRoute() === 'roles' ? 'active' : ''"
          :navText="isRTL ? 'اشتراك' : 'Rol'"
        >
          <template v-slot:icon>
            <i class="ni ni-ungroup text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->
        </ul>
    </div>

    <div v-if="isGoogle" class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
        <ul class="navbar-nav">
            <li class="nav-item">
                <sidenav-item to="/alumno/dashboard" :class="getRoute() === 'dashboard' ? 'active' : ''"
                    :navText="isRTL ? 'لوحة القيادة' : 'Dashboard'">
                    <template v-slot:icon>
                        <i class="ni ni-tv-2 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
            <li class="mt-3 nav-item">
                <h6 v-if="isRTL" class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    صفحات المرافق
                </h6>
                <h6 v-else class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
                    :class="isRTL ? 'me-4' : 'ms-2'">
                    OPCIONES
                </h6>
            </li>
            <!-- <li class="nav-item">
                <sidenav-item to="/alumno-reporte" :class="getRoute() === 'alumno-reporte' ? 'active' : ''"
                    :navText="isRTL ? 'الجداول' : 'Reportes'">
                    <template v-slot:icon>
                        <i class="ni ni-cloud-download-95 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li> -->
            <li class="nav-item">
                <sidenav-item to="/alumno/perfil" :class="getRoute() === 'alumno/perfil' ? 'active' : ''"
                    :navText="isRTL ? 'الفواتیر' : 'Mi Perfil'">
                    <template v-slot:icon>
                        <i class="ni ni-hat-3 text-dark text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>            
        </ul>
    </div>
</template>
