import api from '@/utils/axios.js';

const url = '/user';

export const loadUsers = async ({ commit }) => {
    const { data } = await api.post(`${url}/get-users`)
    const users = []
    for(let id of Object.keys( data )) {
        users.push({
            id,
            ...data[id]
        })
    }
    console.log("estoy en la accion", users)
    commit('setUsers', users)
}

export const updateUsers = async (/*{ commit }*/) => {

}

export const createUsers = async (/*{ commit }*/) => {

}